import React from 'react';
import {Link} from 'react-router-dom';

const items = {
  home: {
    uri: '/',
    title: 'Home'
  },
  about: {
    uri: '/about',
    title: 'About'
  },
  product: {
    uri: '/product',
    title: 'Product'
  },
  pricing: {
    uri: '/pricing',
    title: 'Pricing'
  },
  news: {
    uri: '/',
    title: 'News'
  },
  login: {
    uri: '/login',
    title: 'Login'
  },
  signup: {
    uri: '/signup',
    title: 'Register'
  },
  adminDashboard: {
    uri: '/admin/dashboard',
    title: 'My Dashboard'
  },
  broadcasterDashboard: {
    uri: '/broadcaster/dashboard',
    title: 'My Dashboard'
  },
  viewerDashboard: {
    uri: '/dashboard',
    title: 'My Dashboard'
  },
  dashboard: {
    uri: '/app',
    title: 'My Dashboard'
  },
  explore: {
    uri: '/app/explore',
    title: 'Explore'
  },
  profile: {
    uri: '#',
    title: 'Profile',
    hasHandler: true
  },
  surgeries: {
    uri: '/app/surgeries',
    title: 'Surgeries'
  },
  newSurgery: {
    uri: '/app/new-surgery',
    title: 'Schedule a New Surgery'
  },
  pastSurgeries: {
    uri: '/app/past-surgeries',
    title: 'Past Surgeries'
  },
  surgeonsIFollow: {
    uri: '/app/subscriptions',
    title: 'Surgeons I follow'
  },
  archivedSurgeries: {
    uri: '/app/archived-surgeries',
    title: 'Archived Surgeries'
  },
  groups: {
    uri: '/app/groups',
    title: 'Groups'
  },
  adminBroadcasters: {
    uri: '/app/broadcasters',
    title: 'Broadcasters'
  },
  adminViewers: {
    uri: '/app/viewers',
    title: 'Viewers'
  },
  adminInvites: {
    uri: '/app/invites',
    title: 'Invites'
  },
  myProfile: {
    uri: '/app/my-profile',
    title: 'Account Settings'
  },
  logout: {
    uri: '#',
    title: 'Logout',
    hasHandler: true
  }
};

export default(id, handler) => {
  const navItem = items[id];

  if (!navItem) {
    console.error('Navbar item not defined for: ', id);
    return null;
  }

  let LinkItem = null;

  if (!navItem.hasHandler) {
    LinkItem = () => <Link className='nav-anchor' to={navItem.uri}>{navItem.title}</Link>;
  } else {
    LinkItem = () => <Link className='nav-anchor' to='#' onClick={handler}>{navItem.title}</Link>
  }

  return (
    <li className="site-navigation__nav__item nav-item">
      <LinkItem/>
    </li>
  );
};
import React from 'react';
import {withRouter} from 'react-router-dom';

import SurgeriesTable from 'components/dashboard/SurgeriesTable';

const PreviousSurgeries = ({history, user}) => {
    const onClickSurgery = (s) => {
        history.push('/app/surgery/' + s._id, {surgery: s});
        // TODO: modify API so it returns only those surgeries where user was registered
    }

    const isSelectable = user && (user.role === 'admin' || user.email === 'wbstetsonmd@gmail.com');

    return (
        <div>
            <SurgeriesTable
                userRole="user"
                type="past"
                title="Archived Surgeries"
                broadcaster
                playback
                deletable
                selectable={isSelectable}
                pageSize={10}
                onEditClick={onClickSurgery}/>
        </div>
    )
}

export default withRouter(PreviousSurgeries);
import React, { useState, useEffect } from "react";
import { makeStyles, Button, Modal, Paper } from "@material-ui/core";
import cogoToast from "cogo-toast";

import { validateEmail } from "service/util";

export default (props) => {
  const emptyForm = {
    firstName: "",
    lastName: "",
    email: "",
  };
  const classes = useStyles();
  const [form, setForm] = useState(emptyForm);

  useEffect(() => {
    setForm(emptyForm);
  }, [props.visible]);

  const handleInputChange = (e) => {
    setForm({
      ...form,
      ...{
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (anyFieldEmpty(form)) {
      cogoToast.warn("Please enter all information");
      return;
    }

    if (!validateEmail(form.email)) {
      cogoToast.warn("Please enter a valid email");
      return;
    }

    props.onSubmit({
      email: form.email.trim(),
      status: 'pending',
    });
  };

  return (
    <Modal
      open={props.visible}
      onClose={props.onClose}
      className={classes.modal}
    >
      <Paper elevation={0} className={classes.modalPaper}>
        <section className={classes.content}>
          <form className={classes.formContainer} onKeyDown={handleKeyDown}>
            <h3 className={classes.formTitle}>New Invite</h3>

            <label htmlFor="form--email" className={classes.label}>
              <span className={classes.formLabelText}>Email</span>
              <input
                id="form--email"
                className={classes.formInput}
                type="text"
                name="email"
                value={form.email}
                onChange={handleInputChange}
                required
              />
            </label>
          </form>

          <section
            style={{
              textAlign: "center",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.actionBtn}
            >
              Send Invite
            </Button>

            <span className={classes.dismisser} onClick={props.onClose}>
              Close
            </span>
          </section>
        </section>
      </Paper>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "500px",
    maxWidth: "95%",
    margin: "50px auto 0",
  },
  modalPaper: {
    padding: "20px",
  },
  actionBtn: {
    color: "#fff",
    textTransform: "none",
    padding: "10px 50px",
    display: "inline-block",
    margin: "10px auto 0 auto",
  },
  dismisser: {
    display: "inline-block",
    margin: "10px 0",
    cursor: "pointer",
    fontSize: "14px",
    color: "#333",
  },
  formTitle: {
    textAlign: "center",
    fontSize: "22px",
    margin: "10px 0 30px 0",
  },
  label: {
    display: "flex",
  },
  formLabelText: {
    flex: "1",
  },
  formInput: {
    flex: "2",
  },
}));

const anyFieldEmpty = (fields) => {
  if (!fields.email || !fields.email.trim() === "") {
    return true;
  }

  return false;
};

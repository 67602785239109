import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {makeStyles, Button} from '@material-ui/core';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle, faSquare, faChevronLeft, faComment} from '@fortawesome/free-solid-svg-icons'

import SectionTitle from 'components/dashboard/SectionTitle';
import SurgeryForm from 'components/dashboard/SurgeryForm';
import SendInvitesBtn from 'components/dashboard/SendInvitesBtn';
import SurgeryFeedbackModal from 'components/dashboard/SurgeryFeedbackModal';
import SurgeryAPI from 'api/surgeries';
import ImagesAPI from 'api/images';
import UsersSvc from 'service/users';

const EditSurgery = (props) => {
    const [surgery,
        setSurgery] = useState(null);
    const [isLoading,
        setIsLoading] = useState(false);
    const [isSubmitLoading,
        setIsSubmitLoading] = useState(false);
    const [formInvoker,
        setFormInvoker] = useState(null);
    const [isFeedbackVisible,
        setIsFeedbackVisible] = useState(false);
    const classes = useStyles();

    const getSurgery = (ID) => {
        setIsLoading(true);

        SurgeryAPI
            .get('broadcaster', ID)
            .then(async(response) => {
                setIsLoading(false);

                if (!response) {
                    cogoToast.error('There was a problem getting surgery details');
                    console.error('Problem getting surgery details: no response');
                    return;
                }

                const {data, err} = response;

                if (err || !data) {
                    cogoToast.error('There was a problem getting surgery details');
                    console.error('Problem getting surgery details:', err);
                    return;
                }

                const hour = Math.floor(data.duration / 60);
                const minute = data.duration % 60;
                const imageList = await ImagesAPI.getAll();

                let isComplete = data.broadcast_status === 300
                    ? true
                    : false;

                if (!isComplete) {
                    // Not complete based on status, check if complete based on date and time
                    const end = moment
                        .tz(data.start_time, data.timezone)
                        .add(data.duration, 'minutes');
                    const now = moment().tz(data.timezone);

                    if (now.isAfter(end)) {
                        isComplete = true;
                    }
                }

                setSurgery({
                    broadcaster: data.broadcaster,
                    description: data.description,
                    durationHr: hour,
                    durationMin: minute,
                    timezone: data.timezone,
                    id: data._id,
                    _id: data._id,
                    image: data.image,
                    imageList: imageList.data,
                    join_url: data.join_url,
                    startDate: moment
                        .tz(data.start_time, data.timezone)
                        .format("YYYY-MM-DD"),
                    startTime: moment
                        .tz(data.start_time, data.timezone)
                        .format("HH:mm"),
                    start_time: data.start_time,
                    startUrl: data.start_url,
                    topic: data.topic,
                    youtubeLink: data.youtubeLink,
                    zoomNum: data.id,
                    isComplete,
                    isPublic: data.is_public,
                    broadcast_status: data.broadcast_status,
                    recording_file_name: data.recording_file_name
                });
            });
    }

    useEffect(() => {
        if (!props.match.params.id) {
            return;
        }

        getSurgery(props.match.params.id);
    }, [props.match.params.id]);

    const prepareSurgery = (form) => {
        return {
            description: form.description,
            duration: form.durationHr * 60 + parseInt(form.durationMin),
            timezone: form.timezone,
            id: form.id,
            image: form.image,
            startTime: moment
                .tz(`${form.startDate} ${form.startTime}`, form.timezone)
                .format(),
            topic: form.topic,
            youtubeLink: form.youtubeLink,
            zoomNum: form.zoomNum,
            isPublic: form.isPublic,
            recording_file_name: form.recording_file_name
        };
    }

    const handleSubmit = (form) => {
        setIsSubmitLoading(true);

        const s = prepareSurgery(form);

        SurgeryAPI
            .update('broadcaster', s)
            .then(response => {
                setIsSubmitLoading(false);

                if (!response) {
                    cogoToast.error('There was a problem editing this surgery');
                    console.error('Problem editing this surgery: no response');
                    return;
                }

                const {err} = response;

                if (err) {
                    cogoToast.error('There was a problem editing this surgery');
                    console.error('Problem editing this surgery: ', err);
                    return;
                }

                cogoToast.success('Surgery edited');
                window
                    .location
                    .reload();
            });
    }

    const handleStartSurgery = () => {
        setFormInvoker(null);
        setTimeout(() => {
            setFormInvoker('start-save');
        }, 100);
    }

    const handleEndSurgery = () => {
        SurgeryAPI
            .updateBroadcastStatus(surgery._id, 300)
            .then(response => {
                const {err} = response;

                if (err) {
                    cogoToast.error('Problem stoping the surgery');
                    return;
                }

                getSurgery(surgery._id);
                handleShowFeedback();
            });
    }

    const handleShowFeedback = () => {
        setIsFeedbackVisible(true);
    }

    const handleHideFeedback = () => {
        setIsFeedbackVisible(false);
    }

    const handleStartSubmit = (form) => {
        const s = prepareSurgery(form);


        SurgeryAPI
            .update('broadcaster', {
            ...s
        })
            .then(response => {
                setIsSubmitLoading(false);

                if (!response) {
                    cogoToast.error('There was a problem saving this surgery');
                    console.error('Problem editing this surgery: no response');
                    return;
                }

                const {err} = response;

                if (err) {
                    cogoToast.error('There was a problem saving this surgery');
                    console.error('Problem editing this surgery: ', err);
                    return;
                }

                const w = window.open(surgery.startUrl, '_blank');
                if (w) {
                    w.focus();
                }

                window.location.reload();
            });
    }

    const renderBanner = () => {
        let note = 'Viewers will be able to join your surgery broadcast 10 minutes before start time' +
                '.';

        if (surgery.isComplete) {
            note = 'The surgery is over.';
        }

        if (surgery.broadcast_status === 200) {
            note = 'The surgery is live now.';
        }

        return (
            <div
                className={classes.banner}
                style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: surgery.isComplete
                    ? '#717171'
                    : '#00a2e5'
            }}>
                {note}
            </div>
        );
    }

    const isLeaveFeedbackVisible = () => {
        if (surgery.isComplete) {
            return true;
        }

        return false;
    }

    return (
        <div>
            {surgery && renderBanner()}

            <span
                onClick={() => props.history.go(-1)}
                className="hover_color-blue cursor-pointer"
                style={{
                display: 'inline-block',
                marginBottom: '30px',
                fontSize: '14px'
            }}>
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    style={{
                    marginRight: '10px'
                }}/>
                Go Back
            </span>

            <section className={classes.header}>
                <SectionTitle title='Surgery Details'/> {(!isLoading && surgery) && <section className={classes.headerActions}>
                    {(!surgery.isComplete) && <SendInvitesBtn
                        btnSize='large'
                        surgery={surgery}
                        style={{
                        padding: '10px 30px',
                        marginLeft: '20px'
                    }}/>}

                    {(!surgery.isComplete && (!surgery.broadcast_status || surgery.broadcast_status === 100)) && <Button
                        className={classes.startBtn}
                        disabled={surgery.isComplete}
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={handleStartSurgery}>
                        <span>
                            <FontAwesomeIcon
                                icon={faCircle}
                                color={surgery.isComplete
                                ? 'grey'
                                : 'red'}/>
                            <span
                                style={{
                                marginLeft: '5px'
                            }}>
                                Start Surgery</span>
                        </span>
                    </Button>}

                    {(surgery.broadcast_status === 200) && <Button
                        className={classes.startBtn}
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={handleEndSurgery}>
                        <span>
                            <FontAwesomeIcon icon={faSquare} color="red"/>
                            <span
                                style={{
                                marginLeft: '5px'
                            }}>
                                End Surgery</span>
                        </span>
                    </Button>}

                    {(isLeaveFeedbackVisible()) && <Button
                        className={classes.inviteBtn}
                        variant='contained'
                        color='primary'
                        onClick={handleShowFeedback}>
                        <FontAwesomeIcon
                            icon={faComment}
                            color="white"
                            style={{
                            marginRight: '5px'
                        }}/>
                        Leave Feedback
                    </Button>}
                </section>}
            </section>

            {isLoading && <div>
                <p>Loading surgery details...</p>
            </div>}

            {(!isLoading && !surgery) && <div>
                <p>There was a problem getting surgery details. Please try again.</p>
            </div>}

            {(!isLoading && surgery) && <React.Fragment>
                <p className={classes.instructionText}>
                    {surgery.isComplete
                        ? 'The surgery is over. You can edit Procedure Name, YouTube URL and Case Details fields.'
                        : 'Please fill out all fields'}
                </p>

                <SurgeryForm
                  mode='edit'
                  form={surgery}
                  isLoading={isSubmitLoading}
                  invoker={formInvoker}
                  onSubmit={handleSubmit}
                  onStartSubmit={handleStartSubmit}
                  user={props.user}
                />
            </React.Fragment>}

            <SurgeryFeedbackModal visible={isFeedbackVisible} onHide={handleHideFeedback} surgery={surgery} user={UsersSvc.getCurrent()}/>
        </div>
    )
}

export default withRouter(EditSurgery);

const useStyles = makeStyles((theme) => ({
    banner: {
        margin: '-30px -20px 20px -20px',
        color: '#fff',
        padding: '12px 30px',
        backgroundColor: '#00a2e5',
        fontSize: '15px'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            flexDirection: 'column',
            marginBottom: '40px'
        }
    },
    headerActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [
            theme
                .breakpoints
                .down('md')
        ]: {
            alignItems: 'normal'
        }
    },
    inviteBtn: {
        color: '#fff',
        padding: '10px 30px',
        textTransform: 'none',
        marginLeft: '20px'
    },
    startBtn: {
        color: '#fff',
        backgroundColor: '#1d2122',
        padding: '10px 30px',
        textTransform: 'none',
        marginLeft: '20px',
        '&:hover': {
            backgroundColor: '#0a0a0a'
        }
    },
    instructionText: {
        marginTop: '-10px',
        fontSize: '16px',
        marginBottom: '40px'
    }
}));
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";
import cogoToast from "cogo-toast";
import { Grid, Button, Modal, Paper } from "@material-ui/core";
import { fabric } from "fabric";
import { io } from "socket.io-client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faVideo,
  faVideoSlash,
  faTimes,
  faVolumeMute,
  faVolumeUp,
  faPencilAlt,
  faEraser,
} from "@fortawesome/free-solid-svg-icons";

import SurgeryAPI from "api/surgeries";
import AgoraAPI from "api/agora";
import UsersSvc from "service/users";

import "./SurgeryBroadcast.scss";
import SurgTimeLogo from "images/surgtime-logo_1.png";

const isLocalhost = window.location.hostname === "localhost";

const URL = process.env.REACT_APP_APP_URL;
const socket = io(URL);

let rtc = {
  localAudioTrack: null,
  localVideoTrack: null,
  client: null,
};

let rtcSecondary = {
  localAudioTrack: null,
  localVideoTrack: null,
  client: null,
};

const _cu = UsersSvc.getCurrent();

const SurgeryBroadcast = (props) => {
  let urlParams = new URLSearchParams(props.location.search);
  const isWebRecorder = urlParams.get("vm") === "web_recorder";
  const surgeryID = props.match.params.id;

  const [currentUser, setCurrentUser] = useState(_cu || {});

  if (!isWebRecorder && (!currentUser || !currentUser.id)) {
    return (
      <div className="broadcast-page-public">
        <p>You must be signed into an account in order to view this surgery.</p>
        <p>
          Please either{" "}
          <Link to={`/signup?lp=app/surgery/broadcast/${surgeryID}`}>
            register
          </Link>{" "}
          or{" "}
          <Link to={`/login?lp=app/surgery/broadcast/${surgeryID}`}>login</Link>{" "}
          if you already have an account.
        </p>
      </div>
    );
  }

  const [surgery, setSurgery] = useState(null);

  const [broadcastState, setBroadcastState] = useState("setup");

  const [availableCameras, setAvailableCameras] = useState([]);

  const [primaryCameraID, setPrimaryCameraID] = useState("");

  const [secondaryCameraID, setSecondaryCameraID] = useState("");

  const [options, setOptions] = useState({});

  const [resourceId, setResourceId] = useState("");

  const [sID, setSID] = useState("");

  const [isMuted, setIsMuted] = useState(false);

  const [isVideoDisabled, setIsVideoDisabled] = useState(false);

  const [
    isEndBroadcastConfirmationVisible,
    setIsEndBroadcastConfirmationVisible,
  ] = useState(false);

  const [canvas, setCanvas] = useState(null);

  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    getSurgery(surgeryID);
  }, []);

  useEffect(() => {
    socket.on('userConnected', (message) => {
      console.log('userConnected', message);
    });

    socket.on('drawing', msg => {
      if (currentUser.id === msg.user_id) return;

      const c = document.getElementById("broadcastPaneBroadcasterBigCanvas").fabric;

      if (c) {
        const img = new Image();
        img.src = msg.content;

        img.onload = () => {
          c.add(new fabric.Image(img));
        };
      }
   });

    socket.on('clearDrawing', msg => {
      if (currentUser.id === msg.user_id) return;

      const c = document.getElementById("broadcastPaneBroadcasterBigCanvas").fabric;

      if (c) {
        c.clear();
      }
    });

    return () => {
      socket.off('userConnected');
      socket.off('drawing');
      socket.off('clearDrawing');
    };
  }, []);

  useEffect(() => {
    if (!options || !options.primary || !options.primary.token) return;

    setBroadcastState("starting");
    handleJoin();
    if (currentUser.isBroadcaster) handleJoinSecondaryCam();
  }, [options]);

  useEffect(() => {
    if (broadcastState === "ended") {
      props.history.push("/app/surgery/" + surgery._id + "?broadcast=done");
    }
  }, [broadcastState]);

  const getSurgery = (ID) => {
    SurgeryAPI.get("user", ID).then((response) => {
      if (!response) {
        cogoToast.error("Problem getting the surgery");
        return;
      }

      const { data, err } = response;

      if (err) {
        cogoToast.error("Problem getting the surgery");
        return;
      }

      setSurgery(data);

      let isBroadcaster = false;
      if (!isWebRecorder && data.broadcaster.id === currentUser.id) {
        isBroadcaster = true;
      }

      const cu = {
        ...currentUser,
        isBroadcaster,
      };
      setCurrentUser(cu);

      if (!cu.id && !isWebRecorder) return; // public user, prevent access

      if (!cu.isBroadcaster && data && data.broadcast_status === 300) {
        cogoToast.success("The surgery broadcast has ended");
        setBroadcastState("ended");
        return;
      }

      if (cu.isBroadcaster) getVideoSources();
      setupBroadcast(cu, data);

      if (!cu.isBroadcaster && data && data.broadcast_status === 200) {
        // For viewers, set the state to live.
        // For broadcaster, don't set it to live so it stays setup - so he can choose the video sources
        setBroadcastState("live");
      }
    });
  };

  const handleCloseSetup = () => {
    props.history.push("/app/surgery/" + surgery._id);
  };

  // Get and list all available video sources.
  // Used for the broadcaster to identify and select the secondary video input source.
  const getVideoSources = () => {
    AgoraRTC.getDevices().then((devices) => {
      // const audioDevices = devices.filter(function(device){
      //     return device.kind === "audioinput";
      // });
      const videoDevices = devices.filter(function (device) {
        return device.kind === "videoinput";
      });

      // var selectedMicrophoneId = audioDevices[0].deviceId;
      // var selectedCameraId = videoDevices[0].deviceId;

      console.log("video devices ", videoDevices);
      setAvailableCameras(videoDevices);
    });
  };

  const handleSelectPrimaryCamera = (event) => {
    setPrimaryCameraID(event.target.value);
    const el = document.getElementById("primaryCamPreview");

    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: { exact: event.target.value },
        },
      })
      .then((stream) => {
        el.srcObject = stream;
      })
      .catch((err) => {
        console.log("Error previewing primary camera: ", err);
      });
  };

  const handleSelectSecondaryCamera = (event) => {
    setSecondaryCameraID(event.target.value);
    const el = document.getElementById("secondaryCamPreview");

    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: { exact: event.target.value },
        },
      })
      .then((stream) => {
        el.srcObject = stream;
      })
      .catch((err) => {
        console.log("Error previewing primary camera: ", err);
      });
  };

  const setupOptions = async (surgeryInfo) => {
    const s = surgery || surgeryInfo;
    const channelName = s.channel_name;

    if (!channelName) {
      cogoToast.error("There was a problem setting up broadcast (EA002)");
    }

    AgoraAPI.generateToken({
      channelName,
    }).then((response) => {
      const { data, err } = response;

      if (err || !data || !data.token) {
        cogoToast.error("There was a problem setting up broadcast (EA001)");
        return;
      }

      const base = {
        appId: process.env.REACT_APP_AGORA_APP_ID,
        channel: channelName,
        token: data.token,
      };

      let primary = {
        ...base,
        uid: !isWebRecorder
          ? `${currentUser.id}+${currentUser.firstName} ${currentUser.lastName}`
          : "654321",
      };

      let secondary = null;

      if (currentUser.isBroadcaster) {
        secondary = {
          ...base,
          uid: `${currentUser.id}_secondary`,
        };
      }

      setOptions({ primary, secondary });
    });
  };

  const handleLeave = async () => {
    // Destroy the local audio and video tracks.
    if (rtc && rtc.localAudioTrack) rtc.localAudioTrack.close();
    if (rtc && rtc.localVideoTrack) rtc.localVideoTrack.close();

    if (currentUser.isBroadcaster && rtcSecondary) {
      rtcSecondary.localAudioTrack.close();
      rtcSecondary.localVideoTrack.close();
    }

    // Traverse all remote users.
    rtc.client.remoteUsers.forEach((user) => {
      // Destroy the dynamically created DIV containers.
      const playerContainer = document.getElementById(user.uid);
      playerContainer && playerContainer.remove();
    });

    if (currentUser.isBroadcaster && rtcSecondary) {
      // Traverse all remote users.
      rtcSecondary.client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV containers.
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });
    }

    // Leave the channel.
    await rtc.client.leave();
    if (currentUser.isBroadcaster && rtcSecondary) {
      await rtcSecondary.client.leave();
    }
  };

  const handleStartBroadcast = () => {
    setupOptions();
  };

  const handleEndBroadcast = () => {
    handleLeave();

    const updateSurgeryStatus = (recordingFileName) => {
      SurgeryAPI.updateBroadcastStatus(surgery._id, 300, {
        recordingFileName,
      }).then((response) => {
        const { err } = response;

        if (err) {
          cogoToast.error("Problem updating the surgery broadcast status");
          return;
        }

        cogoToast.success("Broadcast ended.");
        setBroadcastState("ended");

        setTimeout(() => {
          props.history.push("/app/surgery/" + surgeryID + "?broadcast=done");
        }, 1000);
      });
    };

    if (!isLocalhost) {
      AgoraAPI.stopRecording({
        resource: resourceId,
        sid: sID,
        channelName: options.primary.channel,
        uid: options.primary.uid,
      })
        .then((response) => {
          const { err } = response;

          if (err) {
            console.log("Problem stopping recording (E1) : ", err);
            cogoToast.error("Problem stopping recording");
            return;
          }

          const exState =
            response &&
            response.data &&
            response.data.serverResponse &&
            response.data.serverResponse.extensionServiceState
              ? response.data.serverResponse.extensionServiceState
              : null;
          if (!exState) {
            updateSurgeryStatus();
            return;
          }

          handleLeave();

          const webRecService = exState.find(
            (s) => s.serviceName === "web_recorder_service"
          );
          if (
            !webRecService ||
            !webRecService.payload ||
            !webRecService.payload.fileList
          ) {
            updateSurgeryStatus();
            return;
          }

          const fileName = webRecService.payload.fileList[1].filename;
          updateSurgeryStatus(fileName);
          cogoToast.success("Recording stopped.");
        })
        .catch((err) => {
          console.log("Error stopping recording", err);
        });
    } else {
      updateSurgeryStatus();
    }
  };

  const handleJoin = async () => {
    // Join an RTC channel.
    await rtc.client.join(
      options.primary.appId,
      options.primary.channel,
      options.primary.token,
      options.primary.uid
    );

    // Create a Fabric Canvas element for drawing
    const c = new fabric.Canvas(
      document.getElementById("broadcastPaneBroadcasterBigCanvas")
    );
    setCanvas(c);
    document.getElementById("broadcastPaneBroadcasterBigCanvas").fabric = c;

    if (isWebRecorder) return;

    // Publish the local audio and video tracks to the RTC channel.
    if (currentUser.isBroadcaster) {
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        cameraId: primaryCameraID,
      });
      await rtc.client.publish([rtc.localVideoTrack]);
    } else {
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      rtc.localAudioTrack.setMuted(true);
      setIsMuted(true);
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);
    }

    // if (isWebRecorder) return;

    // Dynamically create a container in the form of a DIV element for playing the local video track.
    const localPlayerContainer = document.createElement("div");
    // Specify the ID of the DIV container. You can use the uid of the local user.
    localPlayerContainer.id = options.primary.uid;

    const cl = currentUser.isBroadcaster
      ? "broadcast-pane-broadcaster-big-video-wrapper"
      : "broadcast-pane-broadcaster-viewer-video-wrapper";
    localPlayerContainer.classList = [cl];
    localPlayerContainer.style.width = currentUser.isBroadcaster
      ? "100%"
      : "160px";
    localPlayerContainer.style.height = currentUser.isBroadcaster
      ? "100%"
      : "120px";

    const rID = currentUser.isBroadcaster
      ? "broadcastPaneBroadcasterBig"
      : "broadcastPaneViewers";
    const root = document.getElementById(rID);
    if (root) root.appendChild(localPlayerContainer);

    // Play the local video track.
    // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
    rtc.localVideoTrack.play(localPlayerContainer);
    console.log("publish success!");

    // If this is the broadcaster that just joined, update the status of the surgery
    if (currentUser.isBroadcaster) handleStartSurgery();
  };

  const handleStartSurgery = () => {
    const acquireRecording = () => {
      AgoraAPI.acquire({
        channelName: options.primary.channel,
        uid: options.primary.uid,
      }).then((response) => {
        const { err, data } = response;

        if (err || !data) {
          cogoToast.error("Problem acquiring resource and resource ID");
          return;
        }
        setResourceId(response.data.resourceId);
        cogoToast.success("Resource acquired");
        startRecording(response.data.resourceId);
      });
    };

    const startRecording = (resID) => {
      AgoraAPI.startRecording({
        resource: resID,
        uid: options.primary.uid,
        channelName: options.primary.channel,
        surgeryID: surgery._id,
        token: options.primary.token,
      }).then((response) => {
        const { err, data } = response;

        if (err) {
          console.log("Problem starting recording (E1) : ", err);
          cogoToast.error("Problem starting recording (E1)");
          return;
        }
        if (!data) {
          console.log("Problem starting recording (E2) : no data in response");
          cogoToast.error("Problem starting recording (E2)");
          return;
        }
        setSID(response.data.sid);
        cogoToast.success("Recording started");
      });
    };

    SurgeryAPI.updateBroadcastStatus(surgery._id, 200).then((response) => {
      const { err } = response;

      if (err) {
        cogoToast.error("Problem updating the surgery broadcast status");
        return;
      }

      setBroadcastState("live");
      cogoToast.success("Broadcast started");

      if (!isLocalhost) acquireRecording();
    });
  };

  const handleToggleDrawing = () => {
    if (isDrawing) {
      handleTurnOffDrawing();
      return;
    }

    canvas.isDrawingMode = true;
    canvas.freeDrawingBrush.width = 2;
    canvas.freeDrawingBrush.color = "#de1010";

    canvas.on("path:created", function (e) {
      e.path.set();
      canvas.renderAll();

      const msg = canvas.toDataURL("image/png").replace("img/png", "image/octet-string");
      socket.emit('drawing', {
        user_id: currentUser.id,
        content: msg
      })
    });

    setIsDrawing(true);
  };

  const handleTurnOffDrawing = () => {
    canvas.isDrawingMode = false;
    setIsDrawing(false);
  };

  const handleEraseDrawing = () => {
    canvas.clear();
    socket.emit('clearDrawing', {
      user_id: currentUser.id,
    });
  };

  const handleJoinSecondaryCam = async () => {
    await rtcSecondary.client.join(
      options.secondary.appId,
      options.secondary.channel,
      options.secondary.token,
      options.secondary.uid
    );
    // Create a local audio track from the audio sampled by a microphone.
    rtcSecondary.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      cameraId: secondaryCameraID,
    });
    // Create a local video track from the video captured by a camera.
    rtcSecondary.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
      cameraId: secondaryCameraID,
    });

    // Publish the local audio and video tracks to the RTC channel.
    await rtcSecondary.client.publish([
      rtcSecondary.localAudioTrack,
      rtcSecondary.localVideoTrack,
    ]);
    // Dynamically create a container in the form of a DIV element for playing the local video track.
    const localPlayerContainer = document.createElement("div");
    localPlayerContainer.id = options.secondary.uid;
    localPlayerContainer.classList = [
      "broadcast-pane-broadcaster-small-video-wrapper",
    ];
    localPlayerContainer.style.width = "100%";
    localPlayerContainer.style.height = "100%";

    const root = document.getElementById("broadcastPaneBroadcasterSmall");
    root.appendChild(localPlayerContainer);

    // Play the local video track.
    // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
    rtcSecondary.localVideoTrack.play(localPlayerContainer);
    console.log("publish success!");
  };

  const setupBroadcast = (currentUser, surgeryInfo) => {
    console.log("setupBroadcast : ", currentUser);
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    if (currentUser.isBroadcaster) {
      rtcSecondary.client = AgoraRTC.createClient({
        mode: "rtc",
        codec: "vp8",
      });
    }
    else {
      // Setup options automatically for the viewer
      setupOptions(surgeryInfo);
    }

    // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
    rtc.client.on("user-published", async (user, mediaType) => {
      console.log("user-published : ", user);
      if (!user || !user.uid) return;
      // NOTES:
      // - if broadcaster, check which video has joined and append it to appropriate id, if viewer append to the bottom strip
      if (
        currentUser.isBroadcaster &&
        (user.uid ===
          `${currentUser.id}+${currentUser.firstName} ${currentUser.lastName}` ||
          user.uid === `${currentUser.id}_secondary`)
      )
        return; // Ignore joining of current user

      // Subscribe to the remote user when the SDK triggers the "user-published" event
      await rtc.client.subscribe(user, mediaType);
      console.log("subscribe success");

      if (user.uid.includes("undefined") || user.uid === "654321") return;

      // If the remote user publishes a video track.
      if (mediaType === "video") {
        // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
        const remoteVideoTrack = user.videoTrack;
        const remoteUserID = user.uid.toString();
        let remotePlayerContainer = document.getElementById(remoteUserID);

        if (remotePlayerContainer) {
          remoteVideoTrack.play(remotePlayerContainer);
          return;
        }

        remotePlayerContainer = document.createElement("div");

        remotePlayerContainer.id = remoteUserID;

        if (
          remoteUserID ===
          `${surgeryInfo.broadcaster.id}+${surgeryInfo.broadcaster.fullName}`
        ) {
          remotePlayerContainer.classList = [
            "broadcast-pane-broadcaster-big-video-wrapper",
          ];
          remotePlayerContainer.style.width = "100%";
          remotePlayerContainer.style.height = "100%";

          const root = document.getElementById("broadcastPaneBroadcasterBig");
          root.appendChild(remotePlayerContainer);
        } else if (remoteUserID === `${surgeryInfo.broadcaster.id}_secondary`) {
          remotePlayerContainer.classList = [
            "broadcast-pane-broadcaster-small-video-wrapper",
          ];
          remotePlayerContainer.style.width = "100%";
          remotePlayerContainer.style.height = "100%";

          const broadcasterName = document.createElement("div");
          broadcasterName.classList = ["broadcaster-name"];
          broadcasterName.innerText = surgeryInfo.broadcaster.fullName;
          remotePlayerContainer.appendChild(broadcasterName);

          const root = document.getElementById("broadcastPaneBroadcasterSmall");
          if (root) root.appendChild(remotePlayerContainer);
        } else {
          remotePlayerContainer.classList = [
            "broadcast-pane-broadcaster-viewer-video-wrapper",
          ];
          remotePlayerContainer.style.width = "160px";
          remotePlayerContainer.style.height = "120px";

          const root = document.getElementById("broadcastPaneViewers");
          if (root) root.appendChild(remotePlayerContainer);

          const viewerName = document.createElement("div");
          viewerName.classList = ["viewer-name"];
          const ix = remoteUserID.indexOf("+") + 1;
          viewerName.innerText = remoteUserID.slice(ix);
          remotePlayerContainer.appendChild(viewerName);
        }

        // Play the remote video track.
        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
        remoteVideoTrack.play(remotePlayerContainer);

        // Or just pass the ID of the DIV container.
        // remoteVideoTrack.play(playerContainer.id);
      }

      // If the remote user publishes an audio track.
      if (mediaType === "audio") {
        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        const remoteAudioTrack = user.audioTrack;
        // Play the remote audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }

      // Listen for the "user-left" event
      rtc.client.on("user-left", (user, type) => {
        // Get the dynamically created DIV container.
        const remotePlayerContainer = document.getElementById(user.uid);
        // Destroy the container.
        if (remotePlayerContainer) {
          remotePlayerContainer.remove();
        }
      });
    });

    rtc.client.on("user-left", async (user, mediaType) => {
      if (user.uid.includes(`${surgeryInfo.broadcaster.id}_secondary`)) {
        cogoToast.success(
          "The surgery broadcast has ended, redirecting to surgery page..."
        );
        setTimeout(() => {
          props.history.push("/app/surgery/" + surgeryID);
        }, 1000);
      }
    });

    rtc.client.on("user-info-updated", async (userID, msg) => {
      if (!userID || !msg) return;

      if (msg === "mute-audio") {
        // show icon on user video indicating he's muted
        const remoteUserID = userID;
        const remotePlayerContainer = document.getElementById(remoteUserID);
        if (!remotePlayerContainer) return;

        const muteIcon = document.createElement("div");
        muteIcon.classList = ["viewer-mute-icon"];
        muteIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#ffffff}</style><path d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM461.64 256l45.64-45.64c6.3-6.3 6.3-16.52 0-22.82l-22.82-22.82c-6.3-6.3-16.52-6.3-22.82 0L416 210.36l-45.64-45.64c-6.3-6.3-16.52-6.3-22.82 0l-22.82 22.82c-6.3 6.3-6.3 16.52 0 22.82L370.36 256l-45.63 45.63c-6.3 6.3-6.3 16.52 0 22.82l22.82 22.82c6.3 6.3 16.52 6.3 22.82 0L416 301.64l45.64 45.64c6.3 6.3 16.52 6.3 22.82 0l22.82-22.82c6.3-6.3 6.3-16.52 0-22.82L461.64 256z"/></svg>`;
        remotePlayerContainer.appendChild(muteIcon);
      } else if (msg === "unmute-audio") {
        // remove icon on user video indicating he's muted
        const remoteUserID = userID;
        const remotePlayerContainer = document.getElementById(remoteUserID);
        if (!remotePlayerContainer) return;

        const muteIcon =
          remotePlayerContainer.getElementsByClassName("viewer-mute-icon")[0];
        if (muteIcon) muteIcon.remove();
      }
    });
  };

  if (!surgery) {
    return (
      <div>
        There was a problem getting surgery information. Please try again.
      </div>
    );
  }

  if (
    currentUser.isBroadcaster &&
    (!availableCameras || !availableCameras.length)
  ) {
    return (
      <div
        style={{
          width: 600,
          margin: "50px auto 0",
          textAlign: "center",
        }}
      >
        There are no video input sources available. Please make sure you have a
        functional web cam and you have allowed browser permissions. Once you
        verify this please refresh the page.
      </div>
    );
  }

  if (broadcastState === "ended") {
    return <div>This surgery broadcast has been ended by the surgeon.</div>;
  }

  const renderAudioControls = () => {
    return (
      <div className="controls">
        {isMuted ? (
          <Button
            className="activate"
            onClick={() => {
              if (currentUser.isBroadcaster) {
                rtcSecondary.localAudioTrack.setMuted(false);
              } else {
                rtc.localAudioTrack.setMuted(false);
              }
              setIsMuted(false);
            }}
          >
            <FontAwesomeIcon
              icon={faVolumeUp}
              style={{
                fontSize: "15px",
                marginRight: "7px",
                marginTop: "0px",
              }}
            />
            Unmute Audio
          </Button>
        ) : (
          <Button
            className="deactivate"
            onClick={() => {
              if (currentUser.isBroadcaster) {
                rtcSecondary.localAudioTrack.setMuted(true);
              } else {
                rtc.localAudioTrack.setMuted(true);
              }
              setIsMuted(true);
            }}
          >
            <FontAwesomeIcon
              icon={faVolumeMute}
              style={{
                fontSize: "15px",
                marginRight: "7px",
                marginTop: "0px",
              }}
            />
            Mute Audio
          </Button>
        )}

        {!currentUser.isBroadcaster ? (
          isVideoDisabled ? (
            <Button
              className="activate"
              onClick={() => {
                if (currentUser.isBroadcaster) {
                  rtcSecondary.localVideoTrack.setMuted(false);
                } else {
                  rtc.localVideoTrack.setMuted(false);
                }
                setIsVideoDisabled(false);
              }}
            >
              <FontAwesomeIcon
                icon={faVideo}
                style={{
                  fontSize: "15px",
                  marginRight: "7px",
                  marginTop: "0px",
                }}
              />
              Enable Video
            </Button>
          ) : (
            <Button
              className="deactivate"
              onClick={() => {
                if (currentUser.isBroadcaster) {
                  rtcSecondary.localVideoTrack.setMuted(true);
                } else {
                  rtc.localVideoTrack.setMuted(true);
                }
                setIsVideoDisabled(true);
              }}
            >
              <FontAwesomeIcon
                icon={faVideoSlash}
                style={{
                  fontSize: "15px",
                  marginRight: "7px",
                  marginTop: "0px",
                }}
              />
              Disable Video
            </Button>
          )
        ) : null}

        {currentUser.isBroadcaster ? (
          <Button
            className="end-btn"
            disabled={!primaryCameraID || !secondaryCameraID}
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsEndBroadcastConfirmationVisible(true);
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                marginRight: "5px",
                fontSize: "16px",
                marginTop: "-2px",
              }}
            />
            End Broadcast
          </Button>
        ) : null}
      </div>
    );
  };

  const surgeryTopic =
    surgery.topic.length > 50 ? surgery.topic.substring(0, 50) : surgery.topic;

  return (
    <div className="broadcast-page-wrapper" style={{
      height: isWebRecorder ? '100vh' : '100%',
    }}>
      {/* SETUP PANE FOR BROADCASTER */}
      {broadcastState === "setup" && currentUser.isBroadcaster ? (
        <div className="setup-pane">
          <div className="selectors-wrapper">
            <div className="video-input-selector">
              <div className="video-input-selector-preview">
                <FontAwesomeIcon
                  icon={faVideo}
                  style={{
                    fontSize: "82px",
                    margin: "28% auto",
                    display: "block",
                    position: "absolute",
                    width: "100%",
                  }}
                />
                <video id="primaryCamPreview" autoPlay></video>
              </div>
              <select
                value={primaryCameraID}
                onChange={handleSelectPrimaryCamera}
              >
                <option disabled defaultChecked value="">
                  Select camera
                </option>
                {availableCameras.map((ac, key) => (
                  <option key={key} value={ac.deviceId}>
                    {ac.label}
                  </option>
                ))}
              </select>
              <span className="instruction-text">
                Select your primary camera
              </span>
            </div>
            <div className="video-input-selector">
              <div className="video-input-selector-preview">
                <FontAwesomeIcon
                  icon={faVideo}
                  style={{
                    fontSize: "82px",
                    margin: "28% auto",
                    display: "block",
                    position: "absolute",
                    width: "100%",
                  }}
                />
                <video id="secondaryCamPreview" autoPlay></video>
              </div>
              <select
                value={secondaryCameraID}
                onChange={handleSelectSecondaryCamera}
              >
                <option disabled defaultChecked value="">
                  Select camera
                </option>
                {availableCameras.map((ac, key) => (
                  <option key={key} value={ac.deviceId}>
                    {ac.label}
                  </option>
                ))}
              </select>
              <span className="instruction-text">
                Select your secondary camera (the one facing you)
              </span>
            </div>
          </div>

          <Button
            className="start-btn"
            disabled={!primaryCameraID || !secondaryCameraID}
            variant="contained"
            color="primary"
            onClick={handleStartBroadcast}
          >
            <FontAwesomeIcon
              icon={faPlay}
              style={{
                marginRight: "8px",
                fontSize: "12px",
              }}
            />
            Start Broadcast
          </Button>

          <div className="setup-closer" onClick={handleCloseSetup}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                marginRight: "5px",
                fontSize: "16px",
              }}
            />
            CLOSE SETUP
          </div>
        </div>
      ) : null}

      {broadcastState === "setup" && !currentUser.isBroadcaster ? (
        <div>
          <h4>Broadcast not started yet</h4>
        </div>
      ) : null}

      {broadcastState === "starting" || broadcastState === "live" ? (
        <div id="broadcastPane" className="broadcast-pane">
          <Grid container spacing={0}>
            <div className="broadcast-pane-top">
              <Grid container>
                <Grid item xs={8}>
                  <div
                    id="broadcastPaneBroadcasterBig"
                    className="broadcast-pane-video broadcast-pane-broadcaster-big"
                  ></div>
                  <canvas id="broadcastPaneBroadcasterBigCanvas"></canvas>

                  {
                    currentUser.isBroadcaster ?
                    <div className="broadcast-pane-broadcaster-annotation-controls">
                    <Button
                      className={
                        isDrawing
                          ? "drawing-btn drawing-btn-active"
                          : "drawing-btn"
                      }
                      variant="contained"
                      onClick={handleToggleDrawing}
                    >
                      <FontAwesomeIcon
                        icon={faPencilAlt}
                        style={{
                          marginRight: "8px",
                          fontSize: "12px",
                        }}
                      />
                    </Button>
                    <Button
                      className="drawing-btn"
                      variant="contained"
                      color="primary"
                      onClick={handleEraseDrawing}
                    >
                      <FontAwesomeIcon
                        icon={faEraser}
                        style={{
                          marginRight: "8px",
                          fontSize: "12px",
                        }}
                      />
                    </Button>
                    </div> : null
                  }
                </Grid>
                <Grid item xs={4}>
                  <div
                    id="broadcastPaneBroadcasterSmall"
                    className="broadcast-pane-video broadcast-pane-broadcaster-small"
                  ></div>
                  <div className="broadcast-pane-broadcaster-small-info">
                    <img
                      src={SurgTimeLogo}
                      alt="SurgTime Logo"
                      className="surgtime-logo"
                    />
                    <div className="broadcast-pane-broadcaster-small-info-details">
                      <p>{surgery.broadcaster.fullName}</p>
                      <p>{surgeryTopic}</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="broadcast-pane-bottom">
              <Grid item xs={12}>
                <div
                  id="broadcastPaneViewers"
                  className="broadcast-pane-viewers"
                ></div>
              </Grid>
            </div>
            {!isWebRecorder ? renderAudioControls() : null}
          </Grid>

          {/* End broadcast confirmation */}
          {isEndBroadcastConfirmationVisible && (
            <Modal
              open={true}
              className="end-broadcast-confirmation-modal"
              onClose={() => {
                setIsEndBroadcastConfirmationVisible(false);
              }}
            >
              <Paper elevation={0}>
                <p
                  style={{
                    marginBottom: 0,
                  }}
                >
                  Are you sure you want to end the broadcast?
                </p>

                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEndBroadcast}
                  >
                    Yes, end broadcast
                  </Button>
                </div>

                <span
                  onClick={() => {
                    setIsEndBroadcastConfirmationVisible(false);
                  }}
                >
                  No, dismiss
                </span>
              </Paper>
            </Modal>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(SurgeryBroadcast);

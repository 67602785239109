import React from 'react';
import {NavLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faHome,
    faVideo,
    faArchive,
    faUserCircle,
    faUsers,
    faUserMd,
    faUserFriends,
    faMailBulk,
    faGlobe,
    faComment
} from '@fortawesome/free-solid-svg-icons'

export default({user}) => {
    const classes = useStyles();

    // Fix since Dr. Stetson cant be set to admin role because he would lose the ability to broadcast then
    const isStetson = user.email === 'wbstetsonmd@gmail.com';    

    return (
        <div className={classes.sidebarMenu}>
            <ul className={classes.sidebarMenuList}>
                {/* All roles */}
                <li className={classes.sidebarMenuListItem}>
                    <NavLink
                        activeClassName='is-active'
                        className={classes.sidebarMenuListItemLink}
                        exact
                        to='/app'>
                        <FontAwesomeIcon
                            icon={faHome}
                            className={classes.sidebarMenuListItemLinkIcon}/>
                        My Dashboard
                    </NavLink>
                </li>

                <li className={classes.sidebarMenuListItem}>
                    <NavLink
                        activeClassName='is-active'
                        className={classes.sidebarMenuListItemLink}
                        to='/app/surgeries'>
                        <FontAwesomeIcon
                            icon={faVideo}
                            className={classes.sidebarMenuListItemLinkIcon}/>
                        Surgeries
                    </NavLink>
                </li>

                {/* Admin and Viewer */}
                {(user.role === 'admin' || user.role === 'user') &&
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/past-surgeries'>
                            <FontAwesomeIcon
                                icon={faArchive}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Archived Surgeries
                        </NavLink>
                    </li>}

                {/* Viewer only */}
                {(user.role === 'user') &&
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/subscriptions'>
                            <FontAwesomeIcon
                                icon={faUserMd}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Subscriptions
                        </NavLink>
                    </li>}

                <li className={classes.sidebarMenuListItem}>
                    <NavLink
                        activeClassName='is-active'
                        className={classes.sidebarMenuListItemLink}
                        to='/app/discover-broadcasters'>
                        <FontAwesomeIcon
                            icon={faGlobe}
                            className={classes.sidebarMenuListItemLinkIcon}/>
                        Discover Broadcasters
                    </NavLink>
                </li>

                {/* Broadcaster only */}
                {(user.role === 'broadcaster') && <React.Fragment>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/archived-surgeries'>
                            <FontAwesomeIcon
                                icon={faArchive}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Archived Surgeries
                        </NavLink>
                    </li>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/subscriptions'>
                            <FontAwesomeIcon
                                icon={faUserMd}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Subscriptions
                        </NavLink>
                    </li>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/groups'>
                            <FontAwesomeIcon
                                icon={faUsers}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Groups
                        </NavLink>
                    </li>
                </React.Fragment>}

                {/* Admin and Dr. Stetson */}
                {(user.role === 'admin' || isStetson) && <React.Fragment>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/invites'>
                            <FontAwesomeIcon
                                icon={faMailBulk}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Invites
                        </NavLink>
                    </li>
                </React.Fragment>}

                {/* Admin only */}
                {(user.role === 'admin') && <React.Fragment>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/broadcasters'>
                            <FontAwesomeIcon
                                icon={faUserMd}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Broadcasters
                        </NavLink>
                    </li>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/viewers'>
                            <FontAwesomeIcon
                                icon={faUserFriends}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Viewers
                        </NavLink>
                    </li>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/invites'>
                            <FontAwesomeIcon
                                icon={faMailBulk}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Invites
                        </NavLink>
                    </li>
                    <li className={classes.sidebarMenuListItem}>
                        <NavLink
                            activeClassName='is-active'
                            className={classes.sidebarMenuListItemLink}
                            to='/app/admin-feedback'>
                            <FontAwesomeIcon
                                icon={faComment}
                                className={classes.sidebarMenuListItemLinkIcon}/>
                            Feedback
                        </NavLink>
                    </li>
                </React.Fragment>}

                {/* All roles */}
                <li className={classes.sidebarMenuListItem}>
                    <NavLink
                        activeClassName='is-active'
                        className={classes.sidebarMenuListItemLink}
                        to='/app/my-profile'>
                        <FontAwesomeIcon
                            icon={faUserCircle}
                            className={classes.sidebarMenuListItemLinkIcon}/>
                        Account Settings
                    </NavLink>
                </li>
            </ul>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    sidebarMenu: {
        flex: 1,
        padding: '30px 30px',
        backgroundColor: '#fff',
        [theme.breakpoints.down('md')]: {
            padding: '30px 15px',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    sidebarMenuList: {
        listStyle: 'none',
        padding: 0
    },
    sidebarMenuListItem: {
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            marginBottom: '40px'
        }
    },
    sidebarMenuListItemLink: {
        color: '#828282',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'none'
        },
        '&.is-active': {
            color: '#000'
        },
        '&:focus': {
            outline: 'none'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        },
    },
    sidebarMenuListItemLinkIcon: {
        marginRight: '27px',
        [theme.breakpoints.down('lg')]: {
            marginRight: '10px',
        },
        [theme.breakpoints.down('md')]: {
            marginRight: '5px',
        }
    }
}));
import moment from "moment";

import UsersSvc from './users';

const getLiveSurgeries = (surgeries) => {
    return surgeries.filter(surgery => {
        if (surgery.broadcast_status === 300 || !surgery.duration) return false;
        const isLive = surgery.broadcast_status === 200;

        // If surgery ended more than 24 hours ago then stop treating it as live even if it's status is 200
        const nowMoment = moment(new Date());
        const endMoment = moment(surgery.start_time);
        const hoursPassed = nowMoment.diff(endMoment, 'hours');
        return isLive && hoursPassed < 24;
    });
}

const getUpcomingSurgeries = (surgeries) => {
    return surgeries.filter(surgery => {
        const originalDate = new Date();
        const time = new Date(originalDate.getTime());
        const currentTime = new Date(time).toISOString();
        // If surgery was scheduled to start more than 3 hours ago then stop treating it as upcoming
        return (!surgery.broadcast_status || surgery.broadcast_status < 300) && surgery.start_time >= moment(currentTime).subtract(3, 'hours').toISOString();
    });
}

const getPreviousSurgeries = (surgeries) => {
    let ytRegex = /^[https://www.youtube.com/]/;

    let isAdmin = false;
    const u = UsersSvc.getCurrent();

    if (u && u.role === 'admin') {
        isAdmin = true
    }

    return surgeries.filter(surgery => {
        if (!surgery.duration) return false;

        const originalDate = new Date();
        const getOffsetTime = new Date(originalDate.getTime() - surgery.duration * 60 * 1000);
        const offsetTime = new Date(getOffsetTime).toISOString();

        if (isAdmin) {
            return surgery.broadcast_status >= 300 || surgery.start_time < offsetTime;
        }

        // Check if surgery has either agora rec. video or YT video
        let hasVideo = surgery.recording_file_name || (surgery.youtubeLink && surgery.youtubeLink.match(ytRegex));
        return  hasVideo && (surgery.broadcast_status >= 300 || surgery.start_time < offsetTime);
    });
}

export default {
    getLiveSurgeries,
    getUpcomingSurgeries,
    getPreviousSurgeries
}
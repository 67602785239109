import React from 'react';
import {withRouter} from 'react-router-dom';

import SurgeriesTable from 'components/dashboard/SurgeriesTable';

const ArchivedSurgeries = ({history, user}) => {
    const handleEdit = (s) => {
        history.push('/app/surgery/' + s._id, {surgery: s})
    }

    const isSelectable = user && (user.role === 'admin' || user.email === 'wbstetsonmd@gmail.com');

    return (
        <div>
            <SurgeriesTable
                user={user}
                userRole="user"
                type="past"
                title="Archived Surgeries"
                playback
                pageSize={10}
                deletable={false}
                selectable={isSelectable}
                onProcedureClick={handleEdit}
                onEditClick={handleEdit}/>
        </div>
    )
}

export default withRouter(ArchivedSurgeries);
import * as React from "react";
import {
  withStyles,
  makeStyles,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faVideo,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import UsersAPI from "api/users";

const getStepLabels = () => {
  return ["Name", "Birthday", "Position", "Source", "Login"];
};

class SignUpFlow extends React.Component {
  state = {
    stepIndex: 0,
    answers: [
      "",
      {
        title: "Select a title",
        first_name: "",
        last_name: "",
      },
      "",
      "",
      {
        selected: "",
        otherValue: "",
      },
      {
        email: "",
        password: "",
      },
    ],
    errorMsg: null,
  };

  componentDidMount() {
    if (this.props.state && this.props.state.loggedIn) {
      window.location = "/app";
    }
  }

  setStepIndex = (stepIndex) => {
    if (!this.currentAnswerIsValid()) {
      cogoToast.warn("Please fill in all the fields with valid values");
      return;
    }

    this.setState({ errorMsg: null, stepIndex });
  };

  currentAnswerIsValid = () => {
    const { stepIndex, answers } = this.state;
    const a = answers[stepIndex];

    if (
      stepIndex === 1 &&
      (a.title === "Select a title" || !a.first_name || !a.last_name)
    ) {
      return false;
    }

    if (stepIndex === 2 && !a) {
      return false;
    }

    if (
      stepIndex === 2 &&
      a &&
      !a.match(
        /^((?:0[1-9])|(?:1[0-2]))\/((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/((?:19|20)\d{2})$/
      )
    ) {
      return false;
    }

    if (stepIndex === 3 && !a) {
      return false;
    }

    if (stepIndex === 4 && !a) {
      return false;
    }

    if (stepIndex === 5 && (!a.email || !a.password)) {
      return false;
    }

    return true;
  };

  setAnswers = (answers, nextStepIndex) => {
    this.setState(
      {
        answers,
      },
      () => {
        if (nextStepIndex) {
          setTimeout(() => {
            this.setState({ stepIndex: nextStepIndex });
          }, 300);
        }
      }
    );
  };

  onComplete = () => {
    if (!this.currentAnswerIsValid()) {
      cogoToast.warn("Please fill in all the fields with valid values");
      return;
    }

    const r = constructReqBody(this.state.answers);

    UsersAPI.signup(r)
      .then((response) => {
        if (!response || response.err) {
          console.log(
            "Error signing up: ",
            response.err.message || "No response"
          );
          throw new Error();
        }

        let url = "/signup-success";

        let urlParams = new URLSearchParams(this.props.location.search);
        const intention = urlParams.get("lp");
        const signupSourceParam = urlParams.get("source")

        if (signupSourceParam === "emailinvite") {
          UsersAPI.updateInviteStatusToRegistered(r.email).then((response) => {
            if (!response || response.err) {
              console.error("Problem updating invite");
            }
          });
        }

        if (intention) {
          url = `/login?lp=${intention}`;
        }

        if (r.role === "broadcaster") {
          url += "?t=bc";
        }

        this.props.history.push(url);
      })
      .catch((err) => {
        this.setState({ errorMsg: "There was a problem registering you" });
      });
  };

  render() {
    const { classes } = this.props;
    const { stepIndex, answers, errorMsg } = this.state;
    const stepLabels = getStepLabels();

    return (
      <div className={classes.singUpFlowRoot}>
        <section className="SignupFlow">
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.gridContainer}
            spacing={8}
          >
            <Grid item xs={12} md={8} className={classes.gridContent}>
              {stepIndex === 0 && (
                <UserType
                  selected={answers[0]}
                  onSelect={(userType) => {
                    let a = [...answers];
                    a[0] = userType;
                    this.setAnswers(a, 1);
                  }}
                />
              )}

              {stepIndex === 1 && (
                <TitleName
                  info={answers[1]}
                  onChange={(field, value) => {
                    let a = [...answers];
                    a[1][field] = value;
                    this.setAnswers(a);
                  }}
                />
              )}

              {stepIndex === 2 && (
                <BirthDate
                  date={answers[2]}
                  onChange={(value) => {
                    let a = [...answers];
                    a[2] = value;
                    this.setAnswers(a);
                  }}
                />
              )}

              {stepIndex === 3 && (
                <Position
                  selected={answers[3]}
                  onSelect={(value) => {
                    let a = [...answers];
                    a[3] = value;
                    this.setAnswers(a, 4);
                  }}
                />
              )}

              {stepIndex === 4 && (
                <Survey
                  selected={answers[4].selected}
                  onSelect={(value) => {
                    let a = [...answers];
                    a[4].selected = value;
                    this.setAnswers(a);
                  }}
                  otherValue={answers[4].otherValue}
                  onOtherChange={(value) => {
                    let a = [...answers];
                    a[4].otherValue = value;
                    this.setAnswers(a);
                  }}
                />
              )}

              {stepIndex === 5 && (
                <Credentials
                  info={answers[5]}
                  onChange={(field, value) => {
                    let a = [...answers];
                    a[5][field] = value;
                    this.setAnswers(a);
                  }}
                />
              )}

              {stepIndex === 5 && errorMsg && (
                <p className={classes.errorMessage}>{errorMsg}</p>
              )}

              {stepIndex !== 0 && (
                <div className={classes.flowControls}>
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.flowBackBtn}
                    onClick={() =>
                      this.setState({
                        stepIndex: stepIndex - 1,
                        errorMsg: null,
                      })
                    }
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={{
                        marginRight: "10px",
                      }}
                    />
                    BACK
                  </Button>

                  <Stepper
                    activeStep={stepIndex - 1}
                    style={{
                      background: "transparent",
                      padding: "0",
                    }}
                  >
                    {stepLabels.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {stepIndex < answers.length - 1 && (
                    <Button
                      variant="contained"
                      disableElevation
                      className={classes.flowContinueBtn}
                      onClick={() => this.setStepIndex(stepIndex + 1)}
                    >
                      CONTINUE
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          marginLeft: "10px",
                        }}
                      />
                    </Button>
                  )}

                  {stepIndex === answers.length - 1 && (
                    <Button
                      variant="contained"
                      disableElevation
                      className={classes.flowContinueBtn}
                      onClick={this.onComplete}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{
                          marginRight: "10px",
                        }}
                      />
                      REGISTER
                    </Button>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </section>
      </div>
    );
  }
}

const rootStyles = {
  singUpFlowRoot: {
    display: "inline-block",
    width: "100%",
    paddingTop: "20px",
  },
  gridContainer: {
    padding: "60px 0 80px 0",
    background: "#fafafa",
  },
  gridContent: {
    textAlign: "center",
    backgroundColor: "#fff",
    border: "1px solid #eee",
    position: "relative",
    padding: "130px 0 190px 0 !important",
  },
  errorMessage: {
    color: "#ff5e00",
    margin: "15px 0 0 0",
  },
  flowControls: {
    display: "flex",
    justifyContent: "space-between",
    position: "absolute",
    left: "0",
    bottom: "0",
    width: "100%",
    padding: "20px 40px",
    margin: "0",
    background: "#fdfdfd",
    borderTop: "1px solid #eee",
  },
  flowBackBtn: {
    backgroundColor: "#fff",
    border: "1px solid #0da3d9",
    color: "#0da3d9",
    fontSize: "15px",
    padding: "10px",
    minWidth: "160px",
  },
  flowContinueBtn: {
    backgroundColor: "#0da3d9",
    borderColor: "#0da3d9",
    color: "#fff",
    fontSize: "15px",
    padding: "10px",
    minWidth: "160px",
  },
};

export default withStyles(rootStyles)(withRouter(SignUpFlow));

// Steps
const UserType = ({ selected, onSelect }) => {
  const classes = useFirstStepStyles();

  return (
    <React.Fragment>
      <h2
        style={{
          fontSize: "1.5rem",
        }}
      >
        To start, tell us what you want to do
      </h2>
      <div className={classes.btnWrapper}>
        <section
          className={classes.firstStepBtn}
          style={
            selected === "broadcaster"
              ? {
                  backgroundColor: "#0da3d9",
                  color: "#fff",
                }
              : {
                  backgroundColor: "transparent",
                  borderColor: "#0da3d9",
                  color: "#0da3d9",
                }
          }
          variant="contained"
          onClick={() => onSelect("broadcaster")}
        >
          <FontAwesomeIcon
            icon={faVideo}
            style={{
              fontSize: "28px",
              marginBottom: "5px",
            }}
          />
          Broadcast and watch
          <span className={classes.firstStepBtnDesc}>
            This option allows you to broadcast surgeries and invite viewers in
            addition to watching other surgeries.
          </span>
        </section>

        <section
          className={classes.firstStepBtn}
          style={
            selected === "user"
              ? {
                  backgroundColor: "#0da3d9",
                  color: "#fff",
                }
              : {
                  backgroundColor: "transparent",
                  color: "#0da3d9",
                }
          }
          variant="contained"
          onClick={() => onSelect("user")}
        >
          <FontAwesomeIcon
            icon={faEye}
            style={{
              fontSize: "28px",
              marginBottom: "5px",
            }}
          />
          Just watch
          <span className={classes.firstStepBtnDesc}>
            If you only want to watch surgeries this option is for you. You may
            update your account and start broadcasting at any time.
          </span>
        </section>
      </div>
    </React.Fragment>
  );
};

const useFirstStepStyles = makeStyles((theme) => ({
  btnWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "50px",
  },
  firstStepBtn: {
    padding: "12px 0",
    width: "330px",
    backgroundColor: "transparent",
    border: "1px solid #0da3d9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 10px",
    "&:focus": {
      outline: "none",
    },
    height: "190px",
    flexDirection: "column",
    borderRadius: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  firstStepBtnDesc: {
    fontSize: "13px",
    padding: "0 20px",
    marginTop: "5px",
  },
}));

// Title and full name
const TitleName = ({ info, onChange }) => {
  // const classes = useSecondStepStyles();

  return (
    <React.Fragment>
      <h2
        style={{
          fontSize: "1.5rem",
        }}
      >
        Great, you've come to the right place!
      </h2>
      <h2
        style={{
          fontSize: "1.5rem",
        }}
      >
        How should we address you?
      </h2>
      <div
        style={{
          marginTop: "30px",
        }}
      >
        <Select
          value={info.title}
          onChange={(e) => onChange("title", e.target.value)}
          variant="outlined"
          style={{
            width: "160px",
            marginRight: "20px",
          }}
        >
          <MenuItem disabled value="Select a title">
            Select a title
          </MenuItem>
          <MenuItem value="Other">Other</MenuItem>
          <MenuItem value="Dr.">Dr.</MenuItem>
          <MenuItem value="PA-C">PA-C</MenuItem>
        </Select>
        <TextField
          className="SignupFlow-TextField"
          placeholder="First name"
          variant="outlined"
          value={info.first_name}
          onChange={(e) => onChange("first_name", e.target.value)}
          style={{
            marginRight: "20px",
          }}
        />
        <TextField
          className="SignupFlow-TextField"
          placeholder="Last name"
          variant="outlined"
          value={info.last_name}
          onChange={(e) => onChange("last_name", e.target.value)}
        />
      </div>
    </React.Fragment>
  );
};

// const useSecondStepStyles = makeStyles((theme) => ({})); Birth date
const BirthDate = ({ date, onChange }) => {
  // const classes = useThirdStepStyles();

  return (
    <React.Fragment>
      <h2
        style={{
          fontSize: "1.5rem",
        }}
      >
        When is your birthday?
      </h2>
      <h5
        style={{
          fontSize: "16px",
        }}
      >
        We never share this with anyone or display it in the app
      </h5>
      <div
        style={{
          marginTop: "30px",
        }}
      >
        {/* eslint-disable-next-line */}
        <TextField
          className="SignupFlow-TextField"
          placeholder="mm/dd/yyyy"
          variant="outlined"
          value={date}
          onChange={(e) =>
            onChange(
              e.target.value
                .replace(/^(\d\d)(\d)$/g, "$1/$2")
                .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
                .replace(/[^\d\/]/g, "")
            )
          }
        />

        {date &&
          !date.match(
            /^((?:0[1-9])|(?:1[0-2]))\/((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/((?:19|20)\d{2})$/
          ) && (
            <div
              style={{
                fontSize: "16px",
                marginTop: "10px",
              }}
            >
              Must be mm/dd/yyyy and valid
            </div>
          )}
      </div>
    </React.Fragment>
  );
};

// const useThirdStepStyles = makeStyles((theme) => ({})); Position
const Position = ({ selected, onSelect }) => {
  const classes = useFourthStepStyles();
  const positions = [
    "Doctor",
    "Fellow",
    "Resident",
    "Student",
    "Educator",
    "Vendor",
    "Medical Staff",
    "Other Healthcare Provider",
  ];

  const renderPositions = (positions, selectedPosition) => {
    return (
      <div>
        {positions.map((p, k) => (
          <Button
            key={k}
            className={classes.positionBtn}
            style={
              p === selectedPosition
                ? {
                    backgroundColor: "#0da3d9",
                    color: "#fff",
                  }
                : {
                    backgroundColor: "transparent",
                    color: "#0da3d9",
                  }
            }
            onClick={() => onSelect(p)}
          >
            {p}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <h2
        style={{
          fontSize: "1.5rem",
        }}
      >
        Which one of these best describes your position?
      </h2>
      <div
        style={{
          marginTop: "30px",
        }}
      >
        {renderPositions(positions, selected)}
      </div>
    </React.Fragment>
  );
};

const useFourthStepStyles = makeStyles((theme) => ({
  positionBtn: {
    border: "1px solid #0da3d9",
    margin: "10px 15px",
    padding: "7px 45px",
    fontSize: "16px",
  },
}));

// Survey
const Survey = ({ selected, onSelect, otherValue, onOtherChange }) => {
  const classes = useSurveyStepStyles();
  const options = ["From a friend", "News", "Social Media", "Search", "Other"];

  const renderOptions = (options, selectedOption) => {
    return (
      <div>
        {options.map((p, k) => (
          <Button
            key={k}
            className={classes.positionBtn}
            style={
              p === selectedOption
                ? {
                    backgroundColor: "#0da3d9",
                    color: "#fff",
                  }
                : {
                    backgroundColor: "transparent",
                    color: "#0da3d9",
                  }
            }
            onClick={() => onSelect(p)}
          >
            {p}
          </Button>
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      <h2
        style={{
          fontSize: "1.5rem",
        }}
      >
        How did you hear about SurgTime?
      </h2>
      <div
        style={{
          marginTop: "30px",
        }}
      >
        {renderOptions(options, selected)}

        {selected && selected === "Other" && (
          <input
            type="text"
            placeholder="Please specify (optional)"
            value={otherValue}
            onChange={(e) => onOtherChange(e.target.value)}
            style={{
              width: "360px",
              textAlign: "center",
              marginTop: "10px",
              borderRadius: "5px",
              borderColor: "#b7b7b7",
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

const useSurveyStepStyles = makeStyles((theme) => ({
  positionBtn: {
    border: "1px solid #0da3d9",
    margin: "10px 15px",
    padding: "7px 45px",
    fontSize: "16px",
  },
}));

// Credentials
const Credentials = ({ info, onChange }) => {
  // const classes = useFifthStepStyles();

  return (
    <React.Fragment>
      <h2
        style={{
          fontSize: "1.5rem",
        }}
      >
        Set your login to complete the registration
      </h2>
      <div
        style={{
          margin: "30px 0",
        }}
      >
        <TextField
          className="SignupFlow-TextField --credential"
          placeholder="Email"
          variant="outlined"
          value={info.email}
          onChange={(e) => onChange("email", e.target.value)}
        />
      </div>
      <div>
        <TextField
          className="SignupFlow-TextField --credential"
          placeholder="Password"
          variant="outlined"
          type="password"
          value={info.password}
          onChange={(e) => onChange("password", e.target.value)}
        />
      </div>
    </React.Fragment>
  );
};

// const useFifthStepStyles = makeStyles((theme) => ({}));

const constructReqBody = (answers) => {
  if (!answers || answers.length === 0) {
    return {};
  }

  return {
    firstName: answers[1].first_name,
    lastName: answers[1].last_name,
    title: answers[1].title,
    role: answers[0],
    dob: answers[2],
    position: answers[3],
    signupSource: answers[4].selected,
    signupSourceOther: answers[4].otherValue,
    email: answers[5].email.toLowerCase(),
    password: answers[5].password,
  };
};

/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
} from "@material-ui/core";
import cogoToast from "cogo-toast";
import moment from "moment";

import SectionTitle from "components/dashboard/SectionTitle";
import EmptyArea from "components/dashboard/EmptyArea";
import UsersAPI from "api/users";

export default (props) => {
  const classes = useStyles();
  const [invites, setInvites] = useState(null);
  const [page, setPage] = React.useState(0);
  const pageSize = props.pageSize || 10;

  const getInvites = () => {
    UsersAPI.listInvites().then((response) => {
      const { data, err } = response;

      if (err || !data) {
        console.error("Error getting invites: ", err);
        setInvites([]);
        cogoToast.error("Problem getting invites");
        return;
      }

      setInvites([...data]);
    });
  };

  useEffect(() => {
    if (!props.data) {
      getInvites();
    } else {
      setInvites(props.data);
    }
  }, []);

  useEffect(() => {
    setInvites(props.data);
  }, [props.data]);

  const [inviteToStopAccess, setInviteToStopAccess] = useState(null);

  const handleStopAccess = (invite) => {
    setInviteToStopAccess(invite);
  };

  const handleCloseStopAccessConfirmation = () => {
    setInviteToStopAccess(null);
  };

  const handleConfirmStopAccess = () => {
    UsersAPI.stopInviteAccess(inviteToStopAccess._id)
      .then(({ err }) => {
        if (err) {
          cogoToast.error("Problem stopping acces for this user");
          return;
        }

        setInviteToStopAccess(null);
        cogoToast.success("Access stopped");
        getInvites();
      })
      .catch((err) => {
        console.error("Error stopping access: ", err);
        cogoToast.error("Problem stopping access for this user");
      });
  };

  const handleAllowAccess = (invite) => {
    UsersAPI.allowInviteAccess(invite._id)
      .then(({ err }) => {
        if (err) {
          cogoToast.error("Problem allowing acces for this user");
          return;
        }

        cogoToast.success("Access allowed");
        getInvites();
      })
      .catch((err) => {
        console.error("Error allowing access: ", err);
        cogoToast.error("Problem allowing access for this user");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <SectionTitle
        title={props.title}
        subTitle={props.subTitle}
        style={props.titleStyle}
      />{" "}
      {/*  */}
      {!invites && <EmptyArea title="Loading invites" />}
      {Array.isArray(invites) && invites.length === 0 && (
        <EmptyArea title="No invites" />
      )}
      {Array.isArray(invites) && invites.length > 0 && (
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table
            className={classes.table}
            style={{
              ...props.tableStyle,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Invite sent</TableCell>
                <TableCell>Registered</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invites
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((i) => {
                  return (
                    <TableRow key={i._id} hover>
                      <TableCell>{i.email}</TableCell>
                      <TableCell>
                        <span style={{ textTransform: "capitalize" }}>
                          {!(i.user && i.user[0].userCreated)
                            ? "Pending"
                            : i.status !== "stopped"
                            ? "Registered"
                            : "Stopped"}
                        </span>
                      </TableCell>
                      <TableCell>
                        {moment(i.inviteCreated).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {i.user && i.user[0].userCreated
                          ? `${moment(i.user[0].userCreated).format(
                              "YYYY-MM-DD"
                            )} (${moment(i.user[0].userCreated).fromNow()})`
                          : "-"}
                      </TableCell>
                      <TableCell align="right">
                        {(i.user && i.user[0] && i.user[0].userCreated) ? (
                          i.status !== "stopped" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleStopAccess(i)}
                            >
                              Stop access
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => handleAllowAccess(i)}
                            >
                              Allow access
                            </span>
                          )
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          <TablePagination
            className={classes.tablePagination}
            component="div"
            count={invites.length}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            page={page}
            onChangePage={handleChangePage}
          />
        </TableContainer>
      )}
      {/* Stop access modal */}
      {inviteToStopAccess && inviteToStopAccess._id && (
        <Modal
          open={true}
          onClose={handleCloseStopAccessConfirmation}
          className={classes.deleteModal}
        >
          <Paper elevation={0} className={classes.deleteModalPaper}>
            <p
              style={{
                marginBottom: 0,
              }}
            >
              Are you sure you want to stop access for
            </p>
            <p>{inviteToStopAccess.email}?</p>

            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.deleteModalActionBtn}
                onClick={handleConfirmStopAccess}
              >
                Yes, stop access
              </Button>
            </div>

            <span
              className={classes.deleteModalDismiss}
              onClick={handleCloseStopAccessConfirmation}
            >
              No, dismiss
            </span>
          </Paper>
        </Modal>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  tableContainer: {
    marginBottom: 25,
    paddingBottom: 55, //to make space for pagination
    minHeight: 300,
    position: "relative",
  },
  tablePagination: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  deleteModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteModalPaper: {
    maxWidth: "500px",
    padding: "30px 50px",
    textAlign: "center",
  },
  deleteModalActionBtn: {
    margin: "40px 0 20px 0",
    color: "#fff",
    textTransform: "none",
    padding: "10px 40px",
  },
  deleteModalDismiss: {
    fontSize: "16px",
    color: "#7b7b7b",
    cursor: "pointer",
    "&:hover": {
      color: "#444",
    },
  },
  statusInactive: {
    color: "#dc5616",
  },
  statusActive: {
    color: "#64dc27",
  },
});

import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {makeStyles, Button} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import InvitesTable from 'components/dashboard/InvitesTable';
import AddInviteModal from 'components/dashboard/AddInviteModal';
import UsersAPI from 'api/users';

const Invites = ({history, create}) => {
    const classes = useStyles();

    const [isAddVisible,
        setIsAddVisible] = useState(false);

    const handleShowAdd = () => {
        setIsAddVisible(true);
    }

    const handleHideAdd = () => {
        setIsAddVisible(false);
    }

    const handleSubmitAdd = (data) => {
        UsersAPI
            .createInvite(data)
            .then(response => {
                if (!response || response.err) {
                    cogoToast.error('Problem adding the broadcaster');
                    return;
                }

                cogoToast.success('Invite sent!');
                handleHideAdd();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            });
    }

    const renderAddBtn = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                className={classes.addBtn}
                onClick={handleShowAdd}>
                <FontAwesomeIcon
                    icon={faUserPlus}
                    style={{
                    marginRight: '10px'
                }}/>
                New Invite
            </Button>
        )
    }

    return (
        <div>
            {create && <React.Fragment>
                {renderAddBtn()}
                <AddInviteModal
                    visible={isAddVisible}
                    onClose={handleHideAdd}
                    onSubmit={handleSubmitAdd}/>
            </React.Fragment>}

            <InvitesTable
                title="Invites"
                email
                deletable
                roleStatus
                pageSize={10}/>
        </div>
    )
}

export default withRouter(Invites);

const useStyles = makeStyles((theme) => ({
    addBtn: {
        color: '#fff',
        float: 'right',
        fontSize: '14px',
        padding: '7px 30px',
        textTransform: 'none',
        '&:focus': {
            outline: 'none'
        }
    }
}));
import axios from "axios";

/*
    Generate RTC token for joining the stream
*/
const generateToken = async (opts) => {
  const URL = `/api/agora/generate-token?channelName=${opts.channelName}`;

  try {
    const response = await axios.get(URL);

    if (response.status === 200) {
      return { data: response.data, err: null };
    }

    throw new Error("Error generating RTC token");
  } catch (error) {
    if (error.response) {
      return { data: null, err: error.response };
    }

    return { data: null, err: error };
  }
};

/*
    Acquire for allocation of resources on Agora servers, returns resource ID
*/
const acquire = async (req) => {
  try {
    const response = await axios.post("/api/agora/acquire", req);

    if (!response) {
      return { data: null, err: "No response" };
    }

    return { data: response.data, err: null };
  } catch (error) {
    if (error.response) {
      return { data: null, err: error.response };
    }
  }
};

/*
    Start recording the session
*/
const startRecording = async (req) => {
    try {
        const response = await axios.post("/api/agora/start-recording", req);
    
        if (!response) {
          return { data: null, err: "No response" };
        }
    
        return { data: response.data, err: null };
      } catch (error) {
        if (error.response) {
          return { data: null, err: error.response };
        }
      }
}

/*
    Stops the session's recording
*/
const stopRecording = async (req) => {
    try {
        const response = await axios.post("/api/agora/stop-recording", req);
    
        if (!response) {
          return { data: null, err: "No response" };
        }
    
        return { data: response.data, err: null };
      } catch (error) {
        if (error.response) {
          return { data: null, err: error.response };
        }
      }
}

export default {
    generateToken,
    acquire,
    startRecording,
    stopRecording,
  };
import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserPlus} from '@fortawesome/free-solid-svg-icons'
import {makeStyles, Button} from '@material-ui/core';
import cogoToast from 'cogo-toast';

import UsersTable from 'components/dashboard/UsersTable';
import AddBroadcasterModal from 'components/dashboard/AddBroadcasterModal';
import UsersAPI from 'api/users';

const Broadcasters = ({history, create}) => {
    const classes = useStyles();

    const handleEdit = (u) => {
        history.push('/app/b/' + u._id, {user: u})
    }

    const [isAddVisible,
        setIsAddVisible] = useState(false);

    const handleShowAdd = () => {
        setIsAddVisible(true);
    }

    const handleHideAdd = () => {
        setIsAddVisible(false);
    }

    const handleSubmitAdd = (data) => {
        UsersAPI
            .createBroadcaster(data)
            .then(response => {
                if (!response || response.err) {
                    cogoToast.error('Problem adding the broadcaster');
                    return;
                }

                cogoToast.success('Added new broadcaster ' + data.firstName + ' ' + data.lastName);
            });
    }

    const renderAddBtn = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                className={classes.addBtn}
                onClick={handleShowAdd}>
                <FontAwesomeIcon
                    icon={faUserPlus}
                    style={{
                    marginRight: '10px'
                }}/>
                Add a Broadcaster
            </Button>
        )
    }

    return (
        <div>
            {create && <React.Fragment>
                {renderAddBtn()}
                <AddBroadcasterModal
                    visible={isAddVisible}
                    onClose={handleHideAdd}
                    onSubmit={handleSubmitAdd}/>
            </React.Fragment>}

            <UsersTable
                type="broadcasters"
                title="Broadcasters"
                email
                deletable
                roleStatus
                onViewClick={handleEdit}
                pageSize={10}/>
        </div>
    )
}

export default withRouter(Broadcasters);

const useStyles = makeStyles((theme) => ({
    addBtn: {
        color: '#fff',
        float: 'right',
        fontSize: '14px',
        padding: '7px 30px',
        textTransform: 'none',
        '&:focus': {
            outline: 'none'
        }
    }
}));
import axios from 'axios';
import moment from 'moment';

/*
    Get surgery by ID
*/
const get = (role, ID) => {
    if (role === 'broadcaster') {
        return getBroadcasterSurgery(ID);
    }

    if (role === 'user') {
        return getViewerSurgery(ID);
    }
}

/*
    Get surgery for broadcaster by ID
*/
const getBroadcasterSurgery = async(ID) => {
    try {
        const response = await axios.get('/api/broadcaster/surgery/' + ID);

        let surg = response.data;
        if (!surg.timezone) {
            surg.timezone = 'America/Los_Angeles';
        }

        return {data: surg, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Get surgery for viewer by ID
*/
const getViewerSurgery = async(ID) => {
    try {
        const response = await axios.get('/api/user/surgery/' + ID);

        let surg = response.data;
        if (!surg.timezone) {
            surg.timezone = 'America/Los_Angeles';
        }

        return {data: surg, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Get surgeries, route forward by user role
*/
const list = (role, type) => {
    if (role === 'admin') {
        return listForAdmin(type);
    }

    if (role === 'broadcaster') {
        return listForBroadcaster(type);
    }

    return listForViewer(type);
}

/*
    Get surgeries for logged in admin
*/
const listForAdmin = async(type) => {
    let endpoint = '/api/admin/surgeries';

    if (type === 'past') {
        endpoint += '/past';
    }

    try {
        const response = await axios.get(endpoint);

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Get surgeries for logged in broadcaster
*/
const listForBroadcaster = async(type) => {
    let endpoint = '/api/broadcaster/surgeries';

    if (type === 'upcoming-others') {
        endpoint += '?others=y'
    }

    if (type === 'past') {
        endpoint += '/past';
    }

    try {
        const response = await axios.get(endpoint);

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Get surgeries for logged in user
*/
const listForViewer = async(type, userID) => {
    let ep = '/api/user/surgeries';

    if (type === 'registered') {
        ep = '/api/user/registeredsurgeries';
    } else if (type === 'past') {
        ep += '?type=past';
    } else if (type === 'upcoming') {
        ep += '?type=upcoming';
    }

    if (userID) {
      ep += (type) ? '&' : '?';
      ep += `user_id=${userID}`;
    }

    try {
        const response = await axios.get(ep);

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
   Get public promotional surgeries
*/
const listPromo = async(limit) => {
    let endpoint = '/api/surgery/promo';

    if (limit) {
        endpoint += `?limit=${limit}`
    }

    try {
        let response = await axios.get(endpoint);

        if (!response || !response.data || response.data.errmsg) {
            throw new Error(response.data.errmsg);
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Create a new surgery
*/
const create = async(info) => {
    const ISO_8601 = moment(`${info.startDate}T${info.startTime}:00`, moment.ISO_8601);

    const surgery = {
        topic: info.topic,
        description: info.description,
        image: info.image,
        broadcaster: info.broadcaster,
        timezone: info.timezone,
        start_time: ISO_8601
            .utc()
            .format(),
        duration: info.durationHr * 60 + parseInt(info.durationMin),
        settings: {},
        isPublic: info.isPublic,
        bearerToken: info.bearerToken
    };

    try {
        const response = await axios.post('/api/broadcaster/surgeries', surgery);

        if (!response || (response.status !== 200 && response.status !== 201)) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Update a surgery
*/
const update = (role, surgery) => {
    if (role === 'broadcaster') {
        return updateBroadcasterSurgery(surgery);
    }
}

/*
    Update broadcaster's surgery
*/
const updateBroadcasterSurgery = async(surgery) => {
    try {
        const response = await axios.put('/api/broadcaster/surgery/' + surgery.id, surgery);

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Delete a surgery
*/
const remove = (role, ID) => {
    if (role === 'broadcaster') {
        return removeBroadcasterSurgery(ID);
    }
}

/*
  Delete multiple surgeries
*/
const removeMultiple = async(ids) => {
    try {
        await axios.post('/api/broadcaster/deletesurgeries', {ids});
        return {data: 'deleted', err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Delete a broadcaster's surgery
*/
const removeBroadcasterSurgery = async(ID) => {
    try {
        await axios.delete('/api/broadcaster/surgery/' + ID);
        return {data: 'deleted', err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Send invitations
*/
const sendInvitations = async(invitation) => {
    try {
        const response = await axios.post('/api/admin/surgery/send-single-invitations', invitation);

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

/*
    Register user for surgery
*/
const register = async(info) => {
    try {
        const response = await axios.put('/api/user/surgery/register', info);

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

/*
    Unregister user from a surgery
*/
const unregister = async(info) => {
    try {
        const response = await axios.put('/api/user/surgery/unregister', info);

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }

        return {data: null, err: error}
    }
}

const getRegisteredViewers = async(ID) => {
    try {
        const response = await axios.get('/api/broadcaster/registered-users/' + ID);

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

const updateBroadcastStatus = async(ID, statusCode, opts = {}) => {
    try {
        const response = await axios.post('/api/broadcaster/surgery-status-update', {
            _id: ID,
            broadcast_status: statusCode,
            recording_file_name: opts.recordingFileName
        });

        if (!response) {
            return {data: null, err: 'No response'};
        }

        return {data: response.data, err: null};
    } catch (error) {
        if (error.response) {
            return {data: null, err: error.response}
        }
    }
}

export default {
    get,
    list,
    listForBroadcaster,
    listForViewer,
    listPromo,
    create,
    update,
    remove,
    removeMultiple,
    sendInvitations,
    register,
    unregister,
    getRegisteredViewers,
    updateBroadcastStatus
}
import React, {Component} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {Button} from '../components/button/button';

const PasswordResetContainer = styled.div `
  background: #fff;
  border: 1px solid #eee;
  width: 700px;
  padding: 40px 0;
  margin: 100px auto 100px auto;
  min-height: min-content;
  display: flex;
  align-items: center;
  justify-content: center;

`

const StyledForm = styled.div `
  display: flex;
  align-items: center;
  input[type=text]{
    background-color: #fff;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    flex-grow: 1;
    margin-right: 1rem;
    display: block;
    font-size: 1em;
    border: none;
    padding: .813em 2em;
    line-height: 1;
  }

`

class PasswordForgot extends Component {
  state = {
    email: '',
    confirmationMsg: null,
    errorMsg: null
  }

  handleChange = event => {
    const {name, value} = event.target;

    this.setState({[name]: value})
  }

  handleSubmit = event => {
    event.preventDefault();
    axios
      .post('/api/user/forgot', {email: this.state.email})
      .then(res => {
        if (res && res.data && res.data.msg === 'successful') {
          this.setState({
            confirmationMsg: 'An e-mail has been sent to ' + this.state.email + ' with further instructions.'
          }, () => {
            setTimeout(() => {
              this
                .props
                .history
                .push('/')
            }, 10000)
          })
        } else {
          this.setState({errorMsg: 'User Not Found. Please try again.'});
        }
      })
      .catch(err => {
        this.setState({errorMsg: 'User Not Found. Please try again.'});
      })
  }

  handleRedirect = () => {
    this.setState({confirmationMsg: null, errorMsg: null, email: ''})
  }

  render() {
    if (this.state.confirmationMsg) {
      return (
        <PasswordResetContainer>
          <p>{this.state.confirmationMsg}</p>
        </PasswordResetContainer>
      )
    } else {
      return (
        <PasswordResetContainer>
          <div>
            <p>Enter your e-mail to reset password</p>

            <StyledForm>
              <input
                type='text'
                placeholder='E-mail'
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '15px 10px',
                width: '330px'
              }}/>
              <Button
                type="submit"
                buttonColor="#4670e8"
                onClick={this.handleSubmit}
                disabled={!this.state.email}>Reset Password</Button>
            </StyledForm>

            {this.state.errorMsg && <p style={{
              marginTop: '20px',
              color: 'orange'
            }}>Problem resetting your password, please try again.</p>}
          </div>
        </PasswordResetContainer>
      )
    }
  }
}

export default PasswordForgot;